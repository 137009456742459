.diagram-modal {
	.p-messages .p-message {
		position: absolute;
		z-index: 1000;
		width: 97vw;
	}

	.actions-3,
	.actions-6 {
		.p-menubar {
			padding: 0;
			background: transparent;

			.p-menuitem-link {
				padding: 0.75rem 1rem;
			}
		}
	}

	.actions-4 {
		.p-slider {
			padding-inline: 1rem;
		}

		.ngx-slider-ticks {
			.ngx-slider-tick:first-child .ngx-slider-tick-value {
				transform: translate(-10%);
			}

			.ngx-slider-tick:last-child .ngx-slider-tick-value {
				transform: translate(-90%);
			}
		}

		.p-tabview .p-tabview-nav {
			li {
				&.p-highlight {
					.p-tabview-nav-link {
						border-top: none !important;
						border-bottom: 2px solid var(--primary-color) !important;
						border-left: none !important;
						border-right: none !important;

						span {
							color: var(--primary-color);
						}
					}
				}

				& .p-tabview-nav-link:focus {
					box-shadow: none !important;
				}

				&:not(.p-highlight),
				&:not(.p-highlight):hover {
					.p-tabview-nav-link {
						border: none !important;

						span {
							color: #adabab;
						}
					}
				}
			}
		}

		.ai-opportunity-select {
			.p-multiselect-token-label {
				width: 100% !important;
			}
		}

		.opportunity-item {
			padding: 0.5rem 0;
			color: #333;
			line-height: 1.2rem;

			&.active {
				background: #f4f8ff;
			}
		}

		@media (max-width: 1122px) {
			.p-tabview {
				.p-tabview-nav {
					flex-wrap: wrap;
				}

				.p-tabview-panel {
					padding: 0.5rem;
				}
			}

			.flex {
				flex-wrap: wrap;
			}

			.opportunity-item {
				padding: 0.5rem;
				text-align: left;
			}

			.flex > div {
				width: 100%;
			}
		}
	}

	.flex.justify-content-end.gap-2 {
		gap: 1rem;
		justify-content: space-between;
		flex-wrap: wrap;
	}

	@media (max-width: 1122px) {
		.flex.justify-content-end.gap-2 {
			flex-direction: column;
			align-items: flex-start;
		}

		.p-button {
			width: 100%;
			text-align: center;
		}

		.p-button .pi {
			margin-right: 0;
		}
	}

	.actions-8 {
		background: #f4f8ff !important;

		.p-accordion-tab {
			margin: 1rem !important;

			.p-accordion-header-link {
				background: #ffffff !important;
			}
		}
	}

	.button-switch {
		.p-inputswitch-slider {
			background: var(--primary-color) !important;
		}

		&.p-inputswitch-checked .p-inputswitch-slider,
		&.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
			background: #7c2df0 !important;
		}
	}

	.button-action {
		&:focus,
		&:hover {
			box-shadow: none !important;
			background: var(--primary-color) !important;
			color: white !important;
		}

		&.btnDiff {
			&:focus,
			&:hover {
				background: linear-gradient(90deg, #ebf2ff 0%, #f0e7ff 100%) !important;

				path {
					stroke: #7c2df0 !important;
				}
			}
		}

		&.btnAI:focus {
			path {
				fill: white !important;
			}
		}

		&.btnGenAI {
			background: #7c2df0 !important;

			&.active {
				background: #f9f5ff !important;
				color: #7c2df0 !important;
			}
		}

		&.cbGenAI {
			background: none !important;

			.p-checkbox-box.p-focus,
			&:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
				box-shadow: none;
				border-color: #7c2df0;
			}

			.p-checkbox-box.p-highlight,
			.p-checkbox-box.p-highlight:hover,
			&:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight,
			&:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
				border-color: #7c2df0;
				background: #7c2df0;
			}
		}
	}

	.canvas-before-span,
	.canvas-after-span {
		position: absolute;
		top: 5rem;
		width: fit-content;
		height: 3rem;
		z-index: 100;
		display: flex;
		justify-content: center;
		align-items: center;
		border: 1px solid black;
		border-radius: 5px;
		padding: 0.5rem 1rem;
	}

	.canvas-before-span {
		left: 5rem;
		border-color: var(--primary-color);
		background: #eef5ff;
		color: var(--primary-color);
	}

	.canvas-after-span {
		right: 5rem;
		border-color: #7c2df0;
		background: #f9f5ff;
		color: #7c2df0 !important;
	}

	#canvas-after,
	#canvas-before {
		.bjs-breadcrumbs {
			display: none !important;
		}
	}

	#canvas-before {
		border-right: 5px dashed #7c2df0;

		.djs-minimap,
		.zoom-controls {
			display: none !important;
		}
	}

	.badge {
		display: inline-block;
		width: 20px;
		height: 20px;
		background-color: #a52819;
		color: white;
		border-radius: 50%;
		text-align: center;
		line-height: 20px;
		font-size: 12px;
		font-weight: bold;
	}

	.btnAI {
		&.active,
		&:hover {
			path {
				fill: white;
			}
		}

		path {
			fill: #1b54f8;
		}
	}

	.subprocess-button {
		position: absolute;
		bottom: 15px;
	}

	.text-color-base {
		color: #0e0e0e;
	}
}

.dialog-diagram-modal,
.diagram-confirm-dialog {
	.p-dialog-content {
		background: white !important;
	}
}

.menu-diagram-slide-items {
	width: 18rem;
	display: flex;
	flex-wrap: wrap;
	gap: 0.5rem;
}

.menu-diagram-technologies {
	width: 30rem;
}

.diagram-subprocess-modal {
	.p-dialog-content {
		background: white !important;
	}

	.subprocess-tree {
		border: none;
		padding: 0;
		max-height: 65vh;
		overflow-y: auto;

		.p-checkbox-disabled {
			display: none;
		}
	}
}

.diagram-technologies-by-ai-solution-modal {
	.p-dialog-header {
		padding-inline: 1rem !important;
		padding-bottom: 0 !important;
	}

	.p-dialog-content {
		padding-top: 0 !important;
		background: white !important;
		color: #333;
		position: relative;

		.btnAI {
			position: absolute;
			right: 0.55rem;
		}

		h3 {
			font-size: 1.2rem;
			color: #333 !important;
			font-weight: bold;
			margin-top: 0.5rem;
		}

		th {
			text-align: left;
		}

		.tech-table {
			overflow: auto;
			width: 100%;
		}

		.tech-table table {
			border: 1px solid #dededf;
			height: 100%;
			width: 100%;
			table-layout: fixed;
			border-collapse: collapse;
			border-spacing: 1px;
			text-align: left;
		}

		.tech-table caption {
			caption-side: top;
			text-align: left;
		}

		.tech-table th {
			border: 1px solid #dededf;
			background-color: #eceff1;
			color: #000000;
			padding: 5px;
		}

		.tech-table td {
			border: 1px solid #dededf;
			background-color: #ffffff;
			color: #000000;
			padding: 5px;
		}
	}
}

.diagram-sub-modal {
	.p-dialog-content {
		background: white !important;
	}
}
