#page-taxonomy {
	.p-tree .p-component-overlay {
		background-color: transparent !important;
		border: none;
		box-shadow: none;
	}

	.selection-office-filter {
		display: flex;
		flex-wrap: wrap;

		.p-button {
			width: 50%;
			background: #f9fafc;
			color: black;
			border: none;
			border-bottom: 1px solid black !important;
			border-bottom-right-radius: 0;
			border-bottom-left-radius: 0;

			&.p-highlight {
				font-weight: bold;
				border-bottom: 2px solid black !important;
			}
		}
	}

	.filter-content {
		height: calc(#{$hPageContent} - 10rem);
		overflow-y: auto;

		.label-filter {
			position: sticky;
			top: 0;
			left: 0;
			right: 0;
			z-index: 10;
			background: var(--primary-color);
			color: white;
			border-radius: 0 0.5rem 0 0;
			border-bottom-right-radius: 0;
			border-bottom-left-radius: 0;
			opacity: 5;
			-webkit-border-radius: 0.5rem 0.5rem 0 0;
			-moz-border-radius: 0 0.5rem 0 0;
			-ms-border-radius: 0 0.5rem 0 0;
			-o-border-radius: 0 0.5rem 0 0;
		}
	}

	.p-tree-container {
		font-weight: bold;
	}

	.p-treenode-leaf > .p-treenode-content .p-treenode-label {
		font-weight: normal;
	}

	.p-treenode-leaf > .p-treenode-content .p-treenode-label:focus {
		margin-left: 2px;
		color: var(--primary-color) !important;
	}

	.p-treenode-selectable.p-highlight {
		border-left: 3px solid var(--primary-color) !important;
		font-weight: bold;
		background: #ecf0fb !important;
		color: var(--primary-color) !important;
	}

	.p-treenode-selectable:focus {
		box-shadow: none !important;
	}

	.p-tree-empty-message {
		padding: 1rem;
	}

	.p-tree {
		border: none !important;
		padding: 0 !important;

		.pi {
			color: var(--primary-color);
		}

		.p-tree-toggler:focus {
			box-shadow: none !important;
		}
	}

	.p-inputtext.search:focus {
		box-shadow: none !important;
		border: 1px solid var(--primary-color);
	}

	.button-view-subprocess {
		&.p-button:not(.p-disabled):not(.p-highlight):hover {
			background: transparent !important;
			border-color: transparent !important;
		}

		.p-button-label {
			color: var(--gray-600) !important;
		}
	}

	.p-tabview-nav-container {
		display: none !important;
	}

	.p-tabview-panels {
		padding: 0 !important;

		.p-tabview-panel {
			height: 100%;
		}
	}

	.data-header {
		background: white !important;
		height: fit-content;

		.grid-header {
			text-align: center;
			margin-inline: 1rem;
			align-items: stretch;

			& > * {
				flex-grow: 1;
			}

			.button-header {
				&:hover,
				&:focus {
					background: #ffffff !important;
					border: none !important;
				}
			}

			p,
			label {
				margin: 0;
				line-height: 1rem;
				white-space: nowrap;
				color: #292b2c;
			}

			span {
				color: var(--primary-color);
			}

			.icon {
				fill: var(--primary-color);
			}
		}
	}

	.scroll-height-0 {
		height: calc(#{$hPageContent} - 10rem);

		.sticky-header {
			position: sticky;
			top: 0;
			left: 0;
			right: 0;
			z-index: 10;
			background: white;
		}
	}

	.scroll-height-1 {
		height: calc(#{$hPageContent} - 3rem);

		.card-template {
			height: 32vh;
			color: black;

			app-image {
				.loading-spinner {
					height: 85% !important;
					margin: 0 !important;
				}

				img {
					height: 85% !important;
				}

				.image-empty {
					padding-top: 2vh !important;
					padding-bottom: 2vh !important;
					width: 42%;
				}
			}

			p {
				height: 15% !important;
				width: 95%;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
	}

	.row-table {
		padding-top: 0;
		padding-bottom: 0;
	}

	.p-button.button-header {
		border: none !important;
		background: #fff;
		color: var(--border-primary);

		&.active {
			background: #eef5ff;
			color: var(--primary-color);
		}
	}

	.filter-tab,
	.filter-view {
		.p-button {
			border: none !important;
			background: #fff;
			color: var(--primary-color);

			&.p-highlight {
				background: #eef5ff;
			}

			&:hover {
				background: #ccccf8 !important;
				color: var(--primary-color) !important;
			}
		}

		&.filter-view .p-button {
			&:nth-child(1) {
				border: 1px solid var(--primary-color) !important;
				border-right: none !important;
			}

			&:nth-child(2) {
				border: 1px solid var(--primary-color) !important;
				border-right: none !important;
				border-left: none !important;
			}

			&:nth-child(3) {
				border: 1px solid var(--primary-color) !important;
				border-left: none !important;
			}
		}

		&.filter-tab .p-button {
			&:nth-child(1) {
				border: 1px solid var(--primary-color) !important;
				border-right: none !important;
			}

			&:nth-child(2) {
				border: 1px solid var(--primary-color) !important;
				border-left: none !important;
			}
		}
	}

	.col-category {
		grid-column: 1 / -1;
		padding: 0;
		margin-block: 0.2rem;
		width: 100%;
		font-size: 11px;
		font-weight: 900;
	}

	.col-title {
		flex: 0 0 auto;
		padding: 0;
		margin-block: 0.2rem;
		width: 100%;
		font-size: 12px;

		p {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			color: var(--primary-color);
		}
	}

	.col-name {
		flex: 0 0 auto;
		padding: 0;
		margin-block: 0.2rem;
		width: 30%;
		font-size: 11px;

		p {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}

	.p-grid.expanded .col-name {
		width: 100%;
	}

	.grid-data {
		&:hover {
			opacity: 0.7;
		}

		.image-grid {
			width: 100%;
			max-height: 6rem;
			object-fit: contain;
		}
	}

	.col-value {
		display: flex;
		flex-direction: column;
		flex: 0 0 auto;
		padding: 0 0.5rem;
		margin-block: 0.2rem;
		width: 10%;
		font-size: 11px;

		& > button {
			flex-grow: 1;
		}
	}

	.col-header {
		margin: 0;
	}

	.p-grid.expanded {
		.cell-data.col-name {
			margin-bottom: 0;
		}

		.col-value:not(.col-header) {
			margin-bottom: 0.7rem;
		}

		.col-header p {
			line-height: 1.25rem;
		}

		.col-value {
			width: 14.1%;
		}
	}

	.p-grid {
		.col-header {
			display: none !important;
			text-align: center;
			color: #c8d3eb;
		}

		&.expanded .col-header {
			display: block !important;
		}
	}

	.grid-header.expanded {
		.col-name,
		.col-value {
			display: none !important;
		}
	}

	.background-heatmap {
		text-align: center !important;
		height: 2rem;
	}

	.background-none {
		@extend .background-heatmap;
		background-color: #f9fafc;
	}

	.background-low {
		@extend .background-heatmap;
		color: #ffffff !important;
		background-color: #569bff;
		border-radius: 6px;
		text-transform: uppercase;
		font-weight: bold;
	}

	.background-medium {
		@extend .background-heatmap;
		color: #ffffff !important;
		background-color: #1b54f8;
		border-radius: 6px;
		text-transform: uppercase;
		font-weight: bold;
	}

	.background-high {
		@extend .background-heatmap;
		color: #ffffff !important;
		background-color: #1532b8;
		border-radius: 6px;
		text-transform: uppercase;
		font-weight: bold;
	}
}

.diagram-modal {
	max-height: 100% !important;

	.p-dialog-content {
		// background: rgb(246, 245, 245);
		background-image: radial-gradient(#acade7 0.5px, rgb(246, 245, 245) 1px);
		background-size: 32px 32px;
		background-color: rgb(21, 22, 100, 0.1);
		overflow: hidden !important;

		.p-tabview-panels {
			padding-right: 0.8rem;
		}

		.p-tabview-panel {
			overflow-y: auto;
			overflow-x: hidden;
			padding-right: 0.8rem;

			&::-webkit-scrollbar {
				width: 5px;
				margin: 1rem;
			}

			&::-webkit-scrollbar-track {
				background: transparent;
				box-shadow: inset 0 0 6px rgba(106, 117, 171, 0.3);
			}

			&::-webkit-scrollbar-thumb {
				background: #a7a7c0;
				border-radius: 2px;
			}
		}
	}

	:not(app-view-diagram-diagram).p-button {
		border: 1px solid #fff;
	}
}

.technologies-tooltip {
	max-width: 12rem;
	width: 12rem;

	&:after,
	&:before {
		display: none !important;
	}

	.p-overlaypanel-content {
		span {
			font-size: 14px;
			font-weight: bold;
		}

		p {
			font-size: 12px;
			line-height: 1.5;
		}
	}
}

.p-overlaypanel .p-overlaypanel-content {
	padding: 1rem;
}

@media (max-width: 600px) {
	#page-taxonomy .col-value {
		font-size: 8px !important;
	}
}

@media (max-width: 800px) {
	#page-taxonomy .col-value {
		font-size: 9px !important;
	}
}

@media (min-width: 801px) {
	#page-taxonomy .col-value {
		font-size: 10px !important;
	}
}
