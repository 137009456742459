@mixin stepper-group($color_border, $color_font) {
	$border: 1px solid $color_border;

	&:nth-child(2) {
		&::after {
			content: '';
			background: linear-gradient(to left, rgba(187, 215, 255, 0), #bbd7ff);
			width: 175%;
			left: 0;
		}
	}

	&:nth-child(3) {
		&::after {
			content: 'Feasibility';
			left: 0;
			width: 125%;
			text-align: right;
		}
	}

	&:nth-child(5) {
		&::after {
			content: '';
			background: linear-gradient(to right, rgba(187, 215, 255, 0), #bbd7ff);
			width: 175%;
			right: 0;
		}
	}

	&:nth-child(2),
	&:nth-child(3),
	&:nth-child(4),
	&:nth-child(5) {
		&::after {
			position: absolute;
			top: -12px;
			padding: 0 5px;
			font-size: 10px;
			letter-spacing: 0.15rem;
			color: $color_font;
			font-weight: 700;
			text-transform: uppercase;
			height: 10px;
			display: inline-block;
			border-radius: 5px;
		}
	}
}

.table-use-cases {
	.p-accordion-header {
		display: none;
	}

	.p-accordion-content {
		border: none !important;
		padding: 0 !important;
	}
}

.use-case-readiness-modal {
	&.p-dialog,
	.p-dialog-content {
		border-radius: 1rem;
		margin: 0;
		padding: 0;
	}

	&.p-dialog {
		z-index: 1101 !important;
	}

	.p-messages .p-message {
		z-index: 100;
		position: absolute;
		top: 0;
		width: 98%;
		left: 0.75rem;
	}

	.mat-stepper-horizontal {
		background: transparent !important;
	}

	.mat-horizontal-stepper-header-container {
		margin-bottom: 3rem;
	}

	.mat-horizontal-content-container {
		padding: 0 !important;
	}

	.mat-mdc-form-field-infix {
		padding-top: 6px !important;
		padding-bottom: 6px !important;
		min-height: auto;
	}

	.mat-mdc-form-field-subscript-wrapper {
		display: none;
	}

	.mat-horizontal-stepper-header-container {
		display: none !important;
	}

	.answer-dropdown {
		width: 100%;
		color: #333;
		border-radius: 8px;
		border: 1px solid #e9edf1;
		font-weight: bold;

		&.p-focus {
			box-shadow: none !important;
			border: solid 1px #939598 !important;
		}
	}

	.button-stepper {
		background: #dedeff;
		color: var(--primary-color);
		border: none;
	}

	app-use-cases-readiness {
		.p-steps {
			position: absolute;
			width: 50%;
			right: 5%;
			z-index: 10;

			.p-menuitem-link {
				display: flex;
				flex-direction: column-reverse;
				padding-bottom: 0.5rem;
				cursor: pointer;
			}

			.p-steps-title {
				font-size: 1rem !important;
				margin-bottom: 0.1rem;
				text-wrap: wrap !important;
				text-align: center;
			}

			.p-highlight .p-steps-number {
				background: var(--primary-color) !important;
			}

			.p-highlight .p-steps-title {
				color: var(--primary-color) !important;
			}

			.p-steps-number {
				border: 2px solid var(--primary-color) !important;
				min-width: 1rem !important;
				height: 1rem !important;
				font-size: 0 !important;
				border-radius: 50% !important;
				background: white !important;
			}

			.p-steps-item {
				width: 16.6%;

				@include stepper-group(#c8d3eb, #569bff);
			}

			.p-steps-item:before {
				height: 2px;
				border: none !important;
				background: var(--primary-color) !important;
				margin-top: 1rem !important;

				@media screen and (max-width: 1650px) {
					//margin-top: 1.35rem !important;
				}
			}

			.p-steps-item.p-highlight ~ * {
				&:before {
					background: rgb(69 69 149 / 13%) !important;
				}
			}

			.p-steps-item.p-highlight:before {
				background: linear-gradient(90deg, var(--primary-color) 50%, #45459500 110%) !important;
			}

			&:not(.step-final) {
				background: rgba(255, 255, 255, 0.49);
			}

			&.step-final {
				.p-steps-title {
					color: white !important;
				}

				.p-highlight .p-steps-number {
					background: var(--primary-color) !important;
				}

				.p-steps-number {
					border: 2px solid white !important;
				}

				.p-steps-item {
					@include stepper-group(#ffffff3a, #ffffff);

					&:before {
						background: white !important;
					}
				}

				.p-steps-item.p-highlight ~ * {
					&:before {
						background: rgb(0 0 0 / 13%) !important;
					}
				}

				.p-steps-item.p-highlight:before {
					background: linear-gradient(90deg, white 50%, #45459500 110%) !important;
				}
			}
		}

		a:focus {
			box-shadow: none !important;
		}

		.stepper-title {
			position: relative;
			top: 1.25rem;
			left: 1rem;
		}

		.close-button {
			top: 1rem;
			right: 1rem;
			color: var(--bluegray-300);
			font-size: 0.25rem;
			height: 2rem !important;
			width: 2rem !important;

			&:hover {
				background: var(--bluegray-400) !important;
				border-color: var(--bluegray-400) !important;
			}

			&.white {
				color: white;

				&:hover {
					color: transparent !important;
					background: white !important;
					border-color: white !important;
				}
			}
		}

		.chart-data {
			position: relative;
			max-width: 10rem;
			height: 3rem;

			canvas {
				height: 4rem !important;
			}

			.percent {
				position: absolute;
				bottom: -35%;
				text-align: center;
				width: 100%;
				font-size: 1rem;
			}
		}

		.tippy-box {
			background-color: #ffffff;
			color: #333;
			-webkit-box-shadow: 3px 3px 5px 2px rgba(0, 0, 0, 0.35);
			-moz-box-shadow: 3px 3px 5px 2px rgba(0, 0, 0, 0.35);
			box-shadow: 3px 3px 5px 2px rgba(0, 0, 0, 0.35);
		}

		.tippy-arrow {
			color: #ffffff;
		}

		.import-workplan {
			.p-dialog-content {
				border-top-right-radius: 0 !important;
				border-top-left-radius: 0 !important;
			}
		}
	}

	app-use-cases-readiness-general-form {
		label {
			color: var(--primary-color);
		}

		.p-editor-content {
			height: 78.5%;
		}
	}

	app-use-cases-readiness-category-form {
		.scrollbar-track-x {
			display: none !important;
		}

		label {
			color: var(--primary-color);
		}

		.ngx-slider .ngx-slider-tick-legend {
			position: absolute;
			top: -24px;
		}

		.p-inputtext:focus,
		.p-inputnumber-input:focus,
		.p-dropdown.p-focus {
			border: 1px solid var(--primary-color) !important;
			box-shadow: 0 0 5px var(--primary-color) !important;
		}

		.p-dropdown {
			width: 100%;
		}

		.text-high {
			color: #7faa3a;
		}

		.text-medium {
			color: #ff9900;
		}

		.text-low {
			color: #cc3333;
		}

		.text-na {
			color: #c1c6c8;
			text-wrap: nowrap !important;
		}

		.tippy-box {
			background: #ffffff;
			color: var(--text-color);
			font-size: 0.7rem;
			border: solid 0.5px #dededf;

			p {
				margin: 0 !important;
				line-height: 1.2rem !important;
			}

			.tippy-arrow {
				display: none !important;
			}
		}
	}

	app-use-cases-readiness-workplan-form {
		.p-tabview-nav-container {
			display: none;
		}

		.card-key-tasks {
			.content {
				overflow: auto;
				height: 40vh;
				padding-top: 1rem;
			}
		}

		.buttons-header {
			position: relative;
			top: 0.5rem;
			right: 0.5rem;
			padding: 0;
			z-index: 10;
		}

		.p-accordion-content {
			border: 0 !important;
		}

		.p-accordion-header {
			display: none !important;
		}

		.p-tabview-panels {
			padding-top: 0;
			padding-bottom: 0;
		}

		.button-accordion:hover {
			background: white !important;
		}
	}

	app-use-cases-readiness-result {
		.icon-tooltip {
			height: 24px;
			width: 24px;

			svg {
				height: 24px;
				width: 24px;
			}

			&:not(.cloud).active .icon {
				fill: #7faa3a !important;
			}

			&.active .icon {
				fill: var(--primary-color) !important;
			}
		}

		.btnAI {
			position: absolute;
			top: 1rem;
			right: 1rem;
		}

		.icon-help {
			position: absolute;
			top: 1.5rem;
			left: 1rem;
		}

		.chart-result {
			width: 60% !important;
			max-width: 15rem;
			margin: auto !important;
			position: relative;

			.percent {
				position: absolute;
				bottom: 0;
				text-align: center;
				width: 100%;
				font-size: 1.5rem;
			}

			.percent-loading {
				position: absolute;
				bottom: 5%;
				left: 40%;
				width: 3rem !important;
			}
		}

		.chart-result-data {
			width: 70% !important;
			max-width: 25rem;
			margin: auto !important;
			position: relative;

			.percent {
				position: absolute;
				bottom: 0;
				text-align: center;
				width: 100%;
				font-size: 2rem;
			}

			.percent-loading {
				position: absolute;
				bottom: 0;
				left: 40%;
				width: 5rem !important;
				height: 2rem !important;
			}
		}

		.progress-analysis {
			.p-progressbar {
				height: 80%;
				font-size: 0.75rem;
			}

			&.box-low .p-progressbar .p-progressbar-value {
				background: #994d4d;
			}

			&.box-medium .p-progressbar .p-progressbar-value {
				background: #99794d;
			}

			&.box-high .p-progressbar .p-progressbar-value {
				background: #66994d;
			}
		}

		.header-benefits {
			background-color: #f2f4f8;
			color: #4d5358;
			text-align: center;
			border: 1px solid #e2e8f0;
			border-left: none;
			border-right: none;
		}

		.header-matrix-1 {
			background-color: #f2f4f8;
			color: #4d5358;
			text-align: center;
			border: 1px solid #e2e8f0;
			border-right: none;
			border-radius: 5px 0 0 5px;
		}

		.header-matrix-2 {
			background-color: #f2f4f8;
			color: #4d5358;
			text-align: center;
			border: 1px solid #e2e8f0;
			border-left: none;
			border-radius: 0 5px 5px 0;
		}

		.infra-high {
			background: #191747;
			color: #ffffff;
		}

		.infra-medium-high {
			background: linear-gradient(to right, #989ee2 0%, #191747 100%);
			color: #ffffff;
		}

		.infra-medium {
			background: #989ee2;
			color: #ffffff;
		}

		.infra-medium-low {
			background: linear-gradient(to right, #eff1fd 0%, #989ee2 100%);
			color: #454595;
		}

		.infra-low {
			background: #eff1fd;
			color: #989ee2;
		}

		.overall-dropdown {
			width: 100%;
			border: none;

			padding-left: 0.75rem;
			padding-right: 0.75rem;

			.p-dropdown-label {
				color: #1b54f8;
				font-size: 28px;
				font-style: normal;
				font-weight: 400;
				line-height: 36px;
			}

			.p-dropdown-trigger {
				display: none;
			}
		}
	}

	app-use-cases-readiness-workplan-form,
	app-use-cases-readiness-result {
		.btnAI {
			&.active,
			&:hover {
				path {
					fill: white;
				}
			}

			path {
				fill: #1b54f8;
			}
		}
	}

	.p-editor-toolbar {
		background: white;
		border-bottom: none;
	}

	.icon-tooltip {
		&.technology {
			svg {
				height: 24px;
				width: 24px;
			}
		}
	}

	.title-use-case {
		width: 40%;
		font-size: 1.5rem;
		color: var(--primary-color);
		text-overflow: ellipsis;
		height: 2rem;
		overflow: hidden;
		white-space: nowrap;
	}
}

.use-case-readiness-modal-close {
	.p-dialog-content {
		border-radius: 0 !important;
		padding: 1rem 2rem !important;
	}
}

.user-case-benefits-modal {
	.p-messages .p-message {
		z-index: 100;
		position: absolute;
		top: 8%;
		width: 98%;
		left: 0.75rem;
	}
}
